@import '~/style';

.adyen-checkout__select-list {
    margin: 0;
    padding: 0;

    background: $color-white;
    border: 1px solid $color-gray-dark;
    border-radius: $border-radius-medium;
    max-height: 140px;
    min-height: 100px;
    min-width: 300px;
    overflow-y: scroll;
    width: 100%;
}

.adyen-checkout__select-list__item {
    display: inline-block;
    padding: 9px;
    border: 1px solid transparent;
    border-bottom-color: $color-gray-light;
    background: $color-white;
    outline: 0;
    width: 100%;
    font-size: $font-size-medium;
    cursor: pointer;
    line-height: 20px;
}

.adyen-checkout__select-list__item:first-child {
    border-top: 0;
}

.adyen-checkout__select-list__item:hover,
.adyen-checkout__select-list__item:focus,
.adyen-checkout__select-list__item:active {
    background: rgba(230, 233, 235, 0.6);
}

.adyen-checkout__select-list__item--selected {
    background: rgba(0, 102, 255, 0.1);
    font-weight: 500;
}

.adyen-checkout__select-list__item--selected:hover,
.adyen-checkout__select-list__item--selected:focus,
.adyen-checkout__select-list__item--selected:active {
    background: rgba(0, 102, 255, 0.15);
}
