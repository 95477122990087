@import '~/style';

.adyen-checkout__checkbox {
    display: block;

    &__label {
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        display: inline-block;
        line-height: 19px;
        color: $color-black;
        font-size: $font-size-small;
        font-weight: normal;
        user-select: none;
    }
}

.adyen-checkout__checkbox__input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked {
        + .adyen-checkout__checkbox__label {
            &:before {
                opacity: 1;
            }

            &:after {
                border: 1px solid $color-primary;
                background-color: $color-primary;
            }
        }

        &:hover + .adyen-checkout__checkbox__label:after {
            box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
            border-color: $color-primary;
        }
    }

    &:focus + .adyen-checkout__checkbox__label:after {
        border: 1px solid $color-primary;
        box-shadow: 0 0 0 2px $color-blue-light;
    }

    &:hover:not(:focus) + .adyen-checkout__checkbox__label:after {
        border-color: #99a3ad;
        box-shadow: 0 0 0 2px $color-gray;
    }

    /* Check */
    + .adyen-checkout__checkbox__label:before {
        border: 1px solid transparent;
        border-bottom: 2px solid $color-white;
        border-right: 2px solid $color-white;
        border-radius: 0 2px 1px 2px;
        content: '';
        height: 11px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
        transition: opacity 0.2s ease-out;
        width: 6px;
        z-index: 1;
    }

    /* Box */
    + .adyen-checkout__checkbox__label:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: $border-radius-small;
        background-color: $color-white;
        border: 1px solid $color-gray-dark;
        z-index: 0;
        transition: background 0.15s ease-out, border 0.05s ease-out, box-shadow 0.1s ease-out;
    }
}

.adyen-checkout__field--consentCheckbox {
    background: $color-gray-light;
    border: 1px solid $color-gray-light;
    border-radius: $border-radius-medium;
    padding: 14px 40px 13px 14px;

    &.adyen-checkout__field--error {
        border-color: $color-red;
    }

    .adyen-checkout-input__inline-validation {
        right: -27px;
        top: 10px;
    }
}
