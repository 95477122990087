.adyen-checkout__paywithgoogle .gpay-button.long {
    width: 100%;
    padding: 15px 24px 13px;
    height: 48px;
    transition: background-color 0.3s ease-out, box-shadow 0.3s ease-out;
    &:focus {
        box-shadow: 0 0 0 2px #99c2ff;
        outline: 0;
    }
}
