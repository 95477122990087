@import '~/style';

.adyen-checkout__giftcard-result {
    position: relative;
    background: $color-white;
    border: 1px solid $color-gray-light;
    width: 100%;
    padding: $spacing-medium;
    margin-bottom: $spacing-small;
    border-radius: $border-radius-large;
}

.adyen-checkout__giftcard-result__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1em;
    font-weight: 400;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.adyen-checkout__giftcard-result__header__title {
    display: flex;
    align-items: center;
}

.adyen-checkout__giftcard-result__name {
    margin-left: $spacing-small;
}

.adyen-checkout__giftcard-result__balance {
    padding: 0;
    list-style: none;
    margin: 16px 0 0;
}

.adyen-checkout__giftcard-result__balance__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-small;

    &:last-child {
        margin-bottom: 0;
    }

    &--remaining-balance {
        font-size: $font-size-small;
    }
}

.adyen-checkout__giftcard-result__balance__value {
    &--amount {
        font-weight: bold;
    }
}
