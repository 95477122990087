@import '~/style';

.adyen-checkout__field-wrapper {
    display: flex;
    width: 100%;
}

.adyen-checkout__field--20 {
    width: 20%;
}

.adyen-checkout__field--30 {
    width: 30%;
}

.adyen-checkout__field--40 {
    width: 40%;
}

.adyen-checkout__field--50 {
    width: 50%;
}

.adyen-checkout__field--60 {
    width: 60%;
}

.adyen-checkout__field--70 {
    width: 70%;
}

.adyen-checkout__field--80 {
    width: 80%;
}

.adyen-checkout__field--col-70 {
    width: calc(70% - 8px);
}

.adyen-checkout__field--col-30 {
    width: calc(30% - 8px);
}

.adyen-checkout__field--col-50 {
    width: calc(50% - 8px);
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-right: 8px;
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 8px;
}

.adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
    margin-bottom: 0;
}

.adyen-checkout__input {
    color: $color-black;
    caret-color: $color-blue;
    font-size: $font-size-medium;
    font-family: inherit;
    display: block;
    height: 40px;
    background: $color-white;
    border: 1px solid $color-gray-dark;
    border-radius: $border-radius-medium;
    padding: 5px 8px;
    position: relative;
    outline: none;
    width: 100%;
    transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
}

.adyen-checkout__input:hover {
    border-color: #99a3ad;
}

.adyen-checkout__input:required {
    box-shadow: none;
}

.adyen-checkout__input[readonly],
.adyen-checkout__input--disabled {
    background: $color-disabled;
    border-color: $color-disabled;
}

.adyen-checkout__input--disabled:hover {
    border-color: $color-disabled;
}

.adyen-checkout__input-wrapper {
    position: relative;
    display: block;
}

.adyen-checkout__input-wrapper--block {
    display: block;
}

.adyen-checkout-input__inline-validation {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
}

.adyen-checkout-input__inline-validation--valid {
    color: $color-success;
}

.adyen-checkout-input__inline-validation--invalid {
    color: $color-alert;
}

.adyen-checkout__input--invalid {
    border-color: $color-alert;
}

.adyen-checkout__input--valid {
    border-bottom-color: $color-success;
}

.adyen-checkout__input--error,
.adyen-checkout__input--invalid,
.adyen-checkout__input--error:hover,
.adyen-checkout__input--invalid:hover {
    border-color: $color-alert;
}

.adyen-checkout__input::placeholder {
    color: $color-gray-dark;
    font-weight: 200;
}

.adyen-checkout__input--date {
    padding-right: 30px;
}

.adyen-checkout__input:active,
.adyen-checkout__input:focus,
.adyen-checkout__input--focus,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus:hover,
.adyen-checkout__input--focus:hover {
    border: 1px solid $color-primary;
    box-shadow: 0 0 0 2px $color-blue-light;
}

.adyen-checkout__input[readonly],
.adyen-checkout__input[readonly]:hover {
    background-color: $color-gray-light;
    border-color: transparent;
    color: $color-gray-darker;
    cursor: default;
}
